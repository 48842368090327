<template>
  <div>
    <FrontEndHeader fill="#be2032" path="supporting" />
    <!-- BEGIN: Create ThankYou Layout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-0 sm:py-10 sm:pb-0 rounded-t"
        >
          {{ page.title }}
        </div>
        <SocialShare :supporting="supporting" />
        <TeamMemberSummary
          v-if="newTeamMembers.length > 0"
          :members="newTeamMembers"
        />
        <TransactionSummary
          v-if="transaction.payload.person"
          :transaction="transaction"
          :supporting="supporting"
        />
        <div class="intro-y mt-6 text-center p-3">
          <a
            class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
            href="https://autismoklahomawalk.org"
            target="_blank"
          >
            Back to Home
          </a>
        </div>
      </div>
    </form>
    <!-- END: Summary Layout -->
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import { useStore } from "@/store";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import TransactionSummary from "@/components/front-end/TransactionSummary";
import TeamMemberSummary from "@/components/front-end/TeamMemberSummary";
import SocialShare from "@/components/front-end/SocialShare";

export default defineComponent({
  components: {
    FrontEndHeader,
    TeamMemberSummary,
    TransactionSummary,
    SocialShare
  },
  setup() {
    const store = useStore();
    const page = reactive({
      title: "Thank You"
    });
    const supportingType = store.state.support.supporting;
    const completedTransaction = store.state.transaction.transaction;
    const newTeamMembers = store.state.teamMembers.addedTeamMembers;

    let transaction = {};
    let supporting = {};
    let teamMembers = [];
    Object.assign(transaction, completedTransaction);
    Object.assign(supporting, supportingType);
    Object.assign(teamMembers, newTeamMembers);

    //Run Clear Store
    const resetStore = async () => {
      console.log("reset");
      await store.dispatch("transaction/resetTransaction");
      await store.dispatch("support/resetSupport");
      await store.dispatch("contact/resetContact");
      await store.dispatch("run/resetRunners");
      await store.dispatch("shirt/resetShirt");
      await store.dispatch("donate/resetDonation");
      await store.dispatch("teamMembers/resetTeamMembers");
      console.log("finish reset");
    };

    resetStore();

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    return {
      page,
      transaction,
      supporting,
      newTeamMembers
    };
  }
});
</script>
