<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-6 flex">
        <h2 class="intro-y text-lg font-medium mt-10">Participant</h2>
      </div>
      <div class="col-span-6 flex justify-end items-end">
        <button
          class="btn btn-primary-outline shadow-md mr-2"
          @click="router.go(-1)"
        >
          <ChevronLeftIcon class="w-4 h-4" /> Back
        </button>
        <router-link
          :to="{ name: 'participants' }"
          class="btn btn-outline shadow-md mr-2"
          ><ChevronLeftIcon class="h-4 w-4 mr-1" />All Participants</router-link
        >
      </div>
    </div>
    <div v-if="personData.firstName" class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-8">
        <div class="intro-y box mt-2">
          <div class="p-8 text-base">
            <h3 class="font-bold">
              Participant Details
            </h3>
            <form class="validate-form" @submit.prevent="savePerson">
              <div class="p-12 px-0">
                <div class="grid grid-cols-12 gap-x-5">
                  <div
                    class="intro-y col-span-12 sm:col-span-12 form-check -mt-2 mb-6"
                  >
                    <div>
                      <label for="campus" class="form-label text-xs">
                        Event Type
                      </label>
                      <select
                        id="event"
                        v-model.trim="personData.event"
                        class="form-select sm:mr-2 p-3 text-base"
                        aria-label="person-event"
                        :value="personData.event"
                      >
                        <option value="piecewalk">AutismOklahoma Walk</option>
                        <option value="5k">5k</option>
                        <option value="donor">Donor</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-span-12">
                    <BasicInfo
                      v-model:firstName="personData.firstName"
                      v-model:lastName="personData.lastName"
                      v-model:emailAddress="personData.email"
                      v-model:phoneNumber="personData.phone"
                      v-model:myEmployer="personData.myEmployer"
                      v-model:useEmployerAsName="personData.useEmployerAsName"
                      :first-name="personData.firstName"
                      :last-name="personData.lastName"
                      :email-address="personData.email"
                      :phone-number="personData.phone"
                      :my-employer="personData.myEmployer"
                      :use-employer-as-name="personData.useEmployerAsName"
                      phone-not-required
                      show-employer
                      back-end
                    />
                  </div>
                  <div class="col-span-12">
                    <div class="grid grid-cols-12 gap-x-5 mb-6">
                      <LifeChurchField
                        v-model:lifeChurch="personData.lifeChurch"
                        v-model:lifeChurchCampus="personData.lifeChurchCampus"
                        class="mt-6 mb-6 hidden"
                        :campus-value="personData.lifeChurchCampus"
                        :is-life-church="personData.lifeChurch"
                      />
                      <Address
                        v-if="personData.address"
                        v-model:address="personData.address.address"
                        v-model:city="personData.address.city"
                        v-model:state="personData.address.state"
                        v-model:zipCode="personData.address.zipcode"
                        class="mb-6"
                        no-shipping
                        :address-value="personData.address.address"
                        :city-value="personData.address.city"
                        :state-value="personData.address.state"
                        :zipcode-value="personData.address.zipcode"
                      />
                    </div>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <div
                      class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                    >
                      <input
                        id="email-verified"
                        v-model.trim="personData.marketing"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="personData.marketing"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="email-verified"
                        >Marketing</label
                      >
                    </div>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <div
                      class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
                    >
                      <input
                        id="email-verified"
                        v-model.trim="personData.anonymous"
                        class="form-check-switch mr-0 ml-0"
                        type="checkbox"
                        :checked="personData.anonymous"
                      />
                      <label
                        class="form-check-label font-medium"
                        for="email-verified"
                        >Anonymous</label
                      >
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-8">
                  <button
                    :disabled="validate.$errors.length > 0"
                    type="submit"
                    class="btn btn-primary w-20 mr-auto"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="intro-y box flex flex-wrap sm:flex-nowrap mt-2">
          <div class="p-6">
            <h3 class="font-bold">
              Event Info
            </h3>
            <div class="text-sm mt-2 text-base">
              <p><strong>Event City</strong> {{ personData.eventCity }}</p>
              <p><strong>Event Year</strong> {{ personData.eventYear }}</p>
            </div>
            <div v-if="personData.teams" class="mt-6">
              <h3 class="font-bold">
                Teams
              </h3>
              <div class="text-sm text-base mt-2">
                <div
                  v-for="team in personData.teams"
                  :key="team.id"
                  class="mr-2 inline-block"
                >
                  <router-link
                    :to="{ path: `/team/${team.id}` }"
                    class="btn btn-outline"
                    ><EyeIcon class="w-4 h-4 mr-2" />
                    {{ team.name }}</router-link
                  >
                </div>
              </div>
            </div>
            <div class="text-sm mt-6">
              <p>
                <em>Created</em>
                {{ toFormattedDate(personData.createdAt) }}
              </p>
              <p>
                <em>Updated</em>
                {{ toFormattedDate(personData.updatedAt) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getParticipant } from "@/api/participants.js";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "@/store";
import { updatePerson } from "@/api/participants";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import Address from "@/components/form-inputs/Address";
import LifeChurchField from "@/components/form-inputs/LifeChurch";

export default defineComponent({
  components: {
    BasicInfo,
    Address,
    LifeChurchField
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const validate = useVuelidate();
    let personData = reactive({});
    let participantId = route.params.id;
    const campuses = store.state.lifeChurchCampuses.locations;

    const getParticipantData = async () => {
      let theParticipant = await getParticipant(participantId, true);
      if (theParticipant.lifeChurchLocation && theParticipant.lifeChurch) {
        let campusName = theParticipant.lifeChurchLocation;
        let foundCampusValue = campuses.find(c => c.name === campusName);
        theParticipant.lifeChurchCampus = foundCampusValue;
      }
      Object.assign(personData, theParticipant);
    };

    const toFormattedDate = date => {
      let createdAt = new Date(date);
      let formattedDate = createdAt.toString();
      return formattedDate;
    };

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "The participant has been updated."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem udpating the participant."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const savePerson = async () => {
      const pickedFields = (({
        id,
        firstName,
        lastName,
        email,
        phone,
        marketing,
        anonymous,
        lifeChurch,
        myEmployer,
        useEmployerAsName,
        event
      }) => ({
        id,
        firstName,
        lastName,
        email,
        phone,
        marketing,
        anonymous,
        lifeChurch,
        myEmployer,
        useEmployerAsName,
        event
      }))(personData);
      if (personData.address) {
        pickedFields.address = personData.address;
      }
      if (personData.lifeChurch) {
        pickedFields.lifeChurchLocation = personData.lifeChurchCampus.name;
      }
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
        sendNotification({ code: true });
      } else {
        const savePersonResult = await updatePerson(pickedFields);
        sendNotification(savePersonResult);
      }
    };

    onMounted(() => {
      getParticipantData();
    });

    return {
      router,
      personData,
      toFormattedDate,
      savePerson,
      validate
    };
  }
});
</script>
<style scoped>
.person-table td {
  border-bottom: 1px solid #ddd;
}
</style>
