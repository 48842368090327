<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Daily Snapshots</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          {{ dailyReportRowData.results.length }} Total Daily Snapshots
        </div>
      </div>
    </div>
    <div class="intro-y mt-5 h-full col-span-12">
      <div class="mb-2 text-right">
        <button class="btn btn-outline mr-6" @click="resetFilters()">
          Reset Filters
        </button>
        <button class="btn btn-outline" @click="exportToCSV()">
          Download CSV export file
        </button>
      </div>
      <AgGridVue
        class="ag-theme-alpine w-full the-report-grid"
        :column-defs="columnDefs"
        :row-data="dailyReportRowData.results"
        :default-col-def="defaultColDef"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { getDailyReports } from "@/api/reports.js";

export default defineComponent({
  components: {
    AgGridVue
  },
  setup() {
    let dailyReportRowData = reactive({ results: [] });
    let gridApi = null;
    // eslint-disable-next-line no-unused-vars
    let gridColumnApi = null;

    let defaultColDef = {
      minWidth: 50,
      filter: true,
      resizable: true
    };

    const setDailyReportData = async () => {
      const dailyReports = await getDailyReports();
      Object.assign(dailyReportRowData, dailyReports);
    };

    const resetFilters = async () => {
      await setDailyReportData();
    };

    onMounted(async () => {
      await setDailyReportData();
    });

    const columnDefs = [
      {
        headerName: "2025 Date",
        field: "comparison.createdAt",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left"
      },
      {
        headerName: "2024 Date",
        field: "createdAt",
        sortable: true,
        filter: "agDateColumnFilter",
        // eslint-disable-next-line no-undef
        filterParams: filterReportDate,
        // eslint-disable-next-line no-undef
        valueFormatter: dateFormatter,
        pinned: "left"
      },
      {
        headerName: "% Total Raised",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalRaised
      },
      {
        headerName: "% Total Teams",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalTeams
      },
      {
        headerName: "% OKC Total Raised",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalOKCRaised
      },
      {
        headerName: "% OKC Total Teams",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalOKCTeams
      },
      {
        headerName: "% Tulsa Total Raised",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalTulsaRaised
      },
      {
        headerName: "% Tulsa Total Teams",
        // eslint-disable-next-line no-undef
        valueGetter: percentTotalTulsaTeams
      },
      {
        headerName: "2025 OKC Total Raised",
        field: "comparison.okcTotalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 OKC Total Raised",
        field: "okcTotalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Tulsa Total Raised",
        field: "comparison.tulsaTotalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Tulsa Total Raised",
        field: "tulsaTotalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Total Raised",
        field: "comparison.totalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Total Raised",
        field: "totalRaised",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 OKC Total Teams",
        field: "comparison.okcTotalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 OKC Total Teams",
        field: "okcTotalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Tulsa Total Teams",
        field: "comparison.tulsaTotalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Tulsa Total Teams",
        field: "tulsaTotalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Total Teams",
        field: "comparison.totalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Total Teams",
        field: "totalTeams",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 OKC Total Participants",
        field: "comparison.okcTotalParticipants",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 OKC Total Participants",
        field: "okcTotalParticipants",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Tulsa Total Participants",
        field: "comparison.tulsaTotalParticipants",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Tulsa Total Participants",
        field: "tulsaTotalParticipants",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2025 Total Participants",
        field: "comparison.totalParticipants",
        filter: "agNumberColumnFilter"
      },
      {
        headerName: "2024 Total Participants",
        field: "totalParticipants",
        filter: "agNumberColumnFilter"
      }
    ];

    const onGridReady = params => {
      gridApi = params.api;
      gridColumnApi = params.columnApi;
    };

    const exportToCSV = () => {
      /** const params = {
        columnSeparator: "|"
      }; **/
      gridApi.exportDataAsCsv();
    };

    return {
      columnDefs,
      dailyReportRowData,
      onGridReady,
      exportToCSV,
      defaultColDef,
      resetFilters
    };
  }
});
let filterReportDate = {
  comparator: function(filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    dateAsString.substring(0, 10);
    var yearMonthDay = dateAsString.split("T");
    var dateParts = yearMonthDay[0].split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]) - 1 //Adjusted for local time specifically on this report
    );

    console.log(cellDate);

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true
};
window.dateFormatter = date => {
  let dt = new Date(date.value);
  dt = dt
    .toLocaleDateString({
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })
    .split("/")
    .join("-");
  dt.split("/").join("-");
  return dt;
  // let dateWithoutTime = date.value.split("T");
  // return dateWithoutTime[0];
};

window.okcLessTulsa = function okcLessTulsa(params) {
  return parseInt(
    params.data.comparison.okcTotalRaised -
      params.data.comparison.tulsaTotalRaised
  );
};

window.percentTotalRaised = function percentTotalRaised(params) {
  return parseFloat(
    (params.data.comparison.totalRaised / params.data.totalRaised) * 100
  ).toFixed(2);
};

window.percentTotalOKCRaised = function percentTotalOKCRaised(params) {
  return parseFloat(
    (params.data.comparison.okcTotalRaised / params.data.okcTotalRaised) * 100
  ).toFixed(2);
};

window.percentTotalTulsaRaised = function percentTotalTulsaRaised(params) {
  return parseFloat(
    (params.data.comparison.tulsaTotalRaised / params.data.tulsaTotalRaised) *
      100
  ).toFixed(2);
};

window.percentTotalTeams = function percentTotalTeams(params) {
  return parseFloat(
    (params.data.comparison.totalTeams / params.data.totalTeams) * 100
  ).toFixed(2);
};

window.percentTotalOKCTeams = function percentTotalOKCTeams(params) {
  return parseFloat(
    (params.data.comparison.okcTotalTeams / params.data.okcTotalTeams) * 100
  ).toFixed(2);
};

window.percentTotalTulsaTeams = function percentTotalTulsaTeams(params) {
  return parseFloat(
    (params.data.comparison.tulsaTotalTeams / params.data.tulsaTotalTeams) * 100
  ).toFixed(2);
};

window.percentTotalParticipants = function percentTotalParticipants(params) {
  return parseFloat(
    (params.data.comparison.totalParticipants / params.data.totalParticipants) *
      100
  ).toFixed(2);
};

window.percentTotalOKCParticipants = function percentTotalOKCParticipants(
  params
) {
  return parseFloat(
    (params.data.comparison.okcTotalParticipants /
      params.data.okcTotalParticipants) *
      100
  ).toFixed(2);
};

window.percentTotalTulsaParticipants = function percentTotalTulsaParticipants(
  params
) {
  return parseFloat(
    (params.data.comparison.tulsaTotalParticipants /
      params.data.tulsaTotalParticipants) *
      100
  ).toFixed(2);
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
.the-report-grid {
  height: 800px;
}
</style>
