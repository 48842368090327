<template>
  <router-link
    :to="{ name: path }"
    tag="a"
    class="flex justify-start items-center max-w-3xl mx-auto pt-4"
    @click="setToDashboard"
  >
    <!--<Logo class="w-6" :fill="fill" />-->
    <span class="block text-red text-xl ml-3 flex-grow">
      <span class="font-bold">
        {{ appState }}
      </span>
      {{ appTitle }}
    </span>
    <span class="block text-gray-600 text-base mr-5 justify-self-end font-bold">
      {{ appYear }}
    </span>
  </router-link>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "@/store";
//import Logo from "@/components/front-end/Logo";

export default defineComponent({
  components: {
    //Logo
  },
  props: {
    fill: {
      type: String,
      default: function() {
        return "#bf202e;";
      },
      required: false
    },
    path: {
      type: String,
      default: function() {
        return "my-team";
      }
    }
  },
  setup() {
    const store = useStore();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appYear = computed(() => store.getters["main/appYear"]);
    const appState = computed(() => store.getters["main/appState"]);
    const isAdmin = ref(false);

    onMounted(async () => {
      const isAdminResponse = await store.getters["auth/isAdmin"];
      isAdmin.value = isAdminResponse;
    });

    return {
      appTitle,
      appYear,
      appState,
      isAdmin
    };
  },
  methods: {
    setToDashboard() {
      cash("body")
        .removeClass("error-page")
        .removeClass("frontend create-team")
        .addClass("main");
    }
  }
});
</script>
