<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: Star of the Team -->
    <div class="col-span-12 md:col-span-8">
      <div
        v-if="!canSetStar"
        class="validate-form intro-y box mt-5 p-6 grid grid-cols-12 gap-6"
      >
        <div class="col-span-12">
          <p class="text-base font-bold pl-2 border-l-2 border-red">
            After you have raised ${{ starLimit }}, you can add your star of the
            team here!
          </p>
        </div>
      </div>
      <form
        v-if="canSetStar"
        class="validate-form intro-y box mt-5 p-6 grid grid-cols-12 gap-6"
        @submit.prevent="saveStarOfTeam()"
      >
        <div class="col-span-12">
          <p class="text-base font-bold pl-2 border-l-2 border-red mb-2">
            Congrats! Use the form below to add your star of the team
          </p>
          <p class="italic">Team stars are celebrated on our team stars page</p>
        </div>
        <h3 class="text-base font-bold mb-6 col-span-12 -mb-1">
          Star of the Team
        </h3>
        <div class="col-span-12">
          <img
            v-if="starOfTheTeamData.imageUrl"
            class="mb-6 lg:w-1/2"
            :src="starOfTheTeamData.imageUrl"
          />
          <button
            id="upload_widget"
            type="button"
            class="btn btn-outline-primary"
            @click="initializeUploadWidget()"
          >
            Upload Photo
          </button>
        </div>
        <FirstName
          v-model="starOfTheTeamData.firstName"
          :first-name="starOfTheTeamData.firstName"
          class="col-span-6"
        />
        <LastName
          v-model="starOfTheTeamData.lastName"
          :last-name="starOfTheTeamData.lastName"
          class="col-span-6"
        />
        <div v-if="newMessage" class="col-span-12 text-base">
          <CKEditor
            v-model="newStarMessage"
            :editor="starOfTeamEditor"
            :config="starOfTeamEditorConfig"
          />
        </div>
        <div v-if="!newMessage" class="col-span-12 text-base">
          <p class="font-bold">Star Message</p>
          <p class="italic mb-6">
            Tell a little bit about Who has inspired you to form your
            AustimOklahoma Walk team.
          </p>
          <span v-html="parsedMessage" />
          <div class="mt-6">
            <button
              type="button"
              class="btn btn-sm btn-outline"
              @click="toggleNewMessage()"
            >
              <PlusIcon class="h-4 w-4" /> Set New Message
            </button>
          </div>
        </div>
        <div class="action mt-6 col-span-12">
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="validate.$errors.length > 0"
          >
            Save Star of Team
          </button>
        </div>
      </form>
    </div>
    <!-- END: Settings -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import FirstName from "@/components/form-inputs/FirstName";
import LastName from "@/components/form-inputs/LastName";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    FirstName,
    LastName
  },
  props: {
    starOfTheTeam: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    totalRaised: {
      type: Number,
      default: function() {
        return 0;
      },
      required: true
    }
  },
  emits: ["updateTeamData"],
  setup(props, { emit }) {
    const newMessage = ref(false);
    const newStarMessage = ref("");
    const canSetStar = ref(false);
    const starOfTeamEditor = ClassicEditor;
    const starOfTeamEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"]
      }
    };
    const store = useStore();
    const rules = {};
    const starLimit = store.state.main.starOfTheTeamLimit;
    if (props.totalRaised >= starLimit) {
      canSetStar.value = true;
    }
    let starOfTheTeamData = reactive(props.starOfTheTeam);
    const validate = useVuelidate(rules, toRefs(starOfTheTeamData));

    const parsedMessage = computed(() => {
      if (starOfTheTeamData.message) {
        const parser = new DOMParser();
        const elem = parser.parseFromString(
          starOfTheTeamData.message,
          "text/html"
        );
        return elem.body.innerText;
      }

      return "";
    });

    if (!props.starOfTheTeam.message) {
      newMessage.value = true;
    }

    const toggleNewMessage = () => {
      newMessage.value = !newMessage.value;
    };

    const saveStarOfTeam = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        if (newMessage) {
          starOfTheTeamData.message = newStarMessage;
        }
        let updateData = {
          starOfTeam: starOfTheTeamData
        };
        newMessage.value = false;
        emit("updateTeamData", updateData);
      }
    };

    const initializeUploadWidget = async () => {
      // eslint-disable-next-line no-undef
      const starImageWidget = await cloudinary.createUploadWidget(
        {
          cloudName: process.env.VUE_APP_CLOUDINARY_CONTAINER,
          uploadPreset: process.env.VUE_APP_CLOUDINARY_STAR_PRESET,
          sources: ["local", "google_drive", "dropbox"],
          showSkipCropButton: false,
          maxFileSize: 7100000,
          styles: {
            palette: {
              window: "#ffffff",
              sourceBg: "#f4f4f5",
              windowBorder: "#90a0b3",
              tabIcon: "#000000",
              inactiveTabIcon: "#555a5f",
              menuIcons: "#555a5f",
              link: "#0433ff",
              action: "#339933",
              inProgress: "#0433ff",
              complete: "#339933",
              error: "#cc0000",
              textDark: "#000000",
              textLight: "#fcfffd"
            }
          }
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            starOfTheTeamData.imageUrl = result.info.secure_url;
          } else {
            console.log(error);
          }
        }
      );

      starImageWidget.open();
    };

    return {
      validate,
      starOfTeamEditor,
      starOfTeamEditorConfig,
      starOfTheTeamData,
      saveStarOfTeam,
      newStarMessage,
      newMessage,
      toggleNewMessage,
      parsedMessage,
      starLimit,
      canSetStar,
      initializeUploadWidget
    };
  }
});
</script>
