<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create RunLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <!--Step 1-->
          <SupportCart
            v-show="runSupportTypeCompleted"
            :supporting="supporting"
          />
          <div
            v-if="!runSupportTypeCompleted"
            class="md:grid md:grid-cols-3 md:gap-6"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Would you like to support a team through your registration?
                  <p class="mt-1 text-sm text-gray-600">
                    Your funds and participation can be applied to a specific
                    team or the general Walk event.
                  </p>
                </h3>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <Supporting
                  v-model:supportType="supporting.type"
                  v-model:eventCity="supporting.eventDetails"
                  v-model:teamSelected="supporting.teamDetails"
                  :support-type="supporting.type"
                  :event-city-value="supporting.eventDetails"
                  :team-selected="supporting.teamDetails"
                />
                <div
                  class="intro-y col-span-12 flex items-center justify-center mt-6"
                >
                  <button
                    v-show="supporting.type !== '' && !runSupportTypeCompleted"
                    type="button"
                    class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
                    :disabled="validate.$errors.length > 0"
                    @click="toggleRunSupportTypeCompleted()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--Step 2-->
          <div
            v-if="runSupportTypeCompleted"
            class="md:grid md:grid-cols-3 md:gap-6 mt-4"
          >
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  How many runners are you registering?
                  <p class="mt-1 text-sm text-gray-600">
                    Please tell us the total runners you are registering.
                  </p>
                </h3>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div class="grid grid-cols-12 gap-4 gap-y-5">
                <div class="intro-y col-span-12 sm:col-span-3">
                  <div class="flex justify-between w-full text-lg">
                    <button
                      type="button"
                      class="btn-icon outline-none focus:outline-none"
                      @click="decrementParticipant()"
                    >
                      <MinusCircleIcon
                        class="w-6 h-6 mr-4 fill-theme-9 inline-block"
                      />
                    </button>
                    <div class="flex-grow text-center bg-gray-200 py-3">
                      {{ runData.quantity }}
                    </div>
                    <button
                      type="button"
                      class="btn-icon outline-none focus:outline-none"
                      @click="incrementParticipant()"
                    >
                      <PlusCircleIcon
                        class="w-6 h-6 ml-4 fill-theme-9 inline-block"
                      />
                    </button>
                  </div>
                </div>
                <div
                  v-show="!runQuantitySet"
                  class="intro-y col-span-12 flex items-center justify-center mt-6"
                >
                  <button
                    type="button"
                    class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
                    :disabled="runData.quantity === 0"
                    @click="toggleRunQuantitySet()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--Step 3-->
          <div v-if="runSupportTypeCompleted && runQuantitySet">
            <div
              v-for="(runner, i) in runData.runners"
              :key="i"
              class="grid grid-cols-12 gap-4 gap-y-5 mb-6 mt-8 border-t-2 border-solid border-gray-200 pt-6"
            >
              <div class="intro-y col-span-6 sm:col-span-12">
                <h3
                  class="text-base font-medium leading-6 text-gray-900 lg:flex lg:justify-between"
                >
                  Runner {{ i + 1 }}
                  <span class="block font-normal text-sm"
                    >Registration Cost
                    <span class="font-bold">${{ runner.price }}</span></span
                  >
                </h3>
              </div>
              <div
                v-if="
                  captain.person && !useMyInfo && i === 0 && !runner.first_name
                "
                class="intro-y col-span-12 sm:col-span-12 form-check flex-wrap mt-2 mb-2"
              >
                <MatchUser
                  v-model="useMyInfo"
                  class="w-full"
                  :is-matched="useMyInfo"
                  text="Auto-fill Runner 1 using my profile information."
                  @change="matchCaptain()"
                />
                <p class="w-full mt-1">
                  You are currently logged in as
                  <span class="font-bold"
                    >{{ captain.person.firstName }}
                    {{ captain.person.lastName }}</span
                  >
                </p>
              </div>
              <div class="intro-y col-span-12 sm:col-span-12">
                <VirtualField
                  v-if="
                    supporting.eventDetails &&
                      supporting.eventDetails.city !== 'Tulsa'
                  "
                  v-model="runner.virtual"
                  :is-virtual="runner.virtual"
                  :id-hack="i"
                  @change="setRunnerPrice(runner.virtual, runner)"
                />
              </div>
              <BasicInfo
                v-model:firstName="runner.first_name"
                v-model:lastName="runner.last_name"
                v-model:emailAddress="runner.email"
                v-model:phoneNumber="runner.phone"
                :first-name="runner.first_name"
                :last-name="runner.last_name"
                :email-address="runner.email"
                :phone-number="runner.phone"
              />
              <AdditionalRunnerInfo
                v-model:gender="runner.gender"
                v-model:birthDate="runner.birthDate"
                :gender="runner.gender"
                :birth-date="runner.birthDate"
              />
              <div class="intro-y col-span-12 sm:col-span-6">
                <ShirtSize v-model="runner.size" :size-value="runner.size" />
              </div>
              <Address
                v-if="runner.virtual"
                v-model:address="runner.address"
                v-model:city="runner.city"
                v-model:state="runner.state"
                v-model:zipCode="runner.zipcode"
                :address-value="runner.address"
                :city-value="runner.city"
                :state-value="runner.state"
                :zipcode-value="runner.zipcode"
              />
              <LifeChurchField
                v-model:lifeChurch="runner.lifeChurch"
                v-model:lifeChurchCampus="runner.lifeChurchCampus"
                :campus-value="runner.lifeChurchCampus"
                :is-life-church="runner.lifeChurch"
                :id-hack="i"
              />
            </div>
            <div
              class="intro-y col-span-12 sm:flex items-center justify-between mt-12"
            >
              <div class="total">
                <div>Total Registration Cost</div>
                <h4 class="text-xl">${{ totalRegistrationCost }}</h4>
              </div>
              <button
                type="button"
                class="btn block w-full intro-y mt-6 py-3 sm:mt-0 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
                :disabled="validate.$errors.length > 0"
                @click="setRunners()"
              >
                Add Runners
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
    <!-- END: Contact Layout -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { defineComponent, onMounted, ref, computed, watch, toRefs } from "vue";
import { useStore } from "@/store";
// import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import Supporting from "@/components/form-inputs/Supporting";
import SupportCart from "@/components/front-end/SupportCart";
import VirtualField from "@/components/form-inputs/Virtual";
import BasicInfo from "@/components/form-inputs/BasicInfo";
import ShirtSize from "@/components/form-inputs/ShirtSize";
import Address from "@/components/form-inputs/Address";
import CancelButton from "@/components/front-end/CancelButton";
import AdditionalRunnerInfo from "@/components/form-inputs/AdditionalRunnerInfo.vue";
import LifeChurchField from "@/components/form-inputs/LifeChurch";

import MatchUser from "@/components/form-inputs/MatchParticipantContact";

export default defineComponent({
  components: {
    FrontEndHeader,
    Supporting,
    SupportCart,
    BasicInfo,
    VirtualField,
    ShirtSize,
    Address,
    CancelButton,
    AdditionalRunnerInfo,
    LifeChurchField,
    MatchUser
  },
  setup() {
    const router = useRouter();
    let validateFields = ref(false);
    let resetFields = ref(false);
    let runSupportTypeCompleted = ref(false);
    let runQuantitySet = ref(false);
    let useMyInfo = ref(false);
    const store = useStore();
    const appSettings = computed(() => store.getters["main/eventSettings"]);
    const contact = store.state.contact.contact;
    const supporting = store.state.support.supporting;
    const runData = store.state.run.runData;
    const runCost = runData.runCost;
    const virtualCost = runData.virtualCost;

    const captain = store.state.auth.user;

    const page = {
      title: "Run the 5k"
    };

    watch(appSettings.value, () => {
      if (!appSettings.value.has5k) {
        router.push({ name: "summary" });
      }
    });

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    /**
     * Go Straight to Runner Quantity if Support has been set.
     */
    if (supporting.type !== "") {
      runSupportTypeCompleted.value = true;
    }

    /**
     * If Quantity is set show runner data
     */
    if (runData.quantity > 0) {
      runQuantitySet.value = true;
    }

    const runnerInitialState = {
      virtual: false,
      price: runCost,
      gender: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      size: "",
      birthDate: "",
      lifeChurch: false,
      lifeChurchCampus: {
        value: 0,
        name: ""
      }
    };

    const setSupportType = async type => {
      runData.support_type = type;
      //Reset Fields When Changed
      runData.event_city = "";
      runData.team_name = "";
    };

    const setRunners = () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        setNewRunners();
      }
    };

    const setNewRunners = async () => {
      let runners = { ...runData };
      await store.dispatch("run/setRunners", runners);
      console.log("finished");
      router.push({ path: "/frontend/contact", query: { skip: true } });
    };

    const setRunnerPrice = (virtual, runner) => {
      if (virtual) {
        runner.price = virtualCost;
      } else {
        runner.price = runCost;
      }
    };

    const totalRegistrationCost = computed(() => {
      let total = 0;
      if (runData.runners.length > 0) {
        total = runData.runners.reduce(function(prev, cur) {
          console.log(cur.price);
          return prev + cur.price;
        }, 0);
      }

      return total;
    });

    const rules = computed(() => {
      return {};
    });

    const matchCaptain = () => {
      if (
        captain.person &&
        captain.person.firstName &&
        runData.runners.length > 0
      ) {
        runData.runners[0].first_name = captain.person.firstName;
        runData.runners[0].last_name = captain.person.lastName;
        runData.runners[0].email = captain.person.email;
        runData.runners[0].phone = captain.person.phone;
      }

      if (
        captain.person &&
        captain.person.lifeChurch &&
        runData.runners.length > 0
      ) {
        const lifeChurchCampusList = store.state.lifeChurchCampuses.locations;
        runData.runners[0].lifeChurch = captain.person.lifeChurch;

        let campusMatch = lifeChurchCampusList.filter(function(campus) {
          return campus.name === captain.person.lifeChurchLocation; // Returns an array of matches
        });
        runData.runners[0].lifeChurchCampus = campusMatch[0]; // Return just the first object match.
      }
    };

    const validate = useVuelidate(rules, toRefs(runData));

    return {
      page,
      validate,
      validateFields,
      resetFields,
      contact,
      supporting,
      runData,
      runSupportTypeCompleted,
      runQuantitySet,
      runnerInitialState,
      setSupportType,
      setRunners,
      setRunnerPrice,
      totalRegistrationCost,
      appSettings,
      captain,
      matchCaptain,
      useMyInfo
    };
  },
  methods: {
    toggleRunSupportTypeCompleted() {
      this.runSupportTypeCompleted = !this.runSupportTypeCompleted;
    },
    toggleRunQuantitySet() {
      this.runQuantitySet = !this.runQuantitySet;
    },
    onChangeMembers() {
      this.runData.runners = [...Array(this.runData.quantity).keys()].map(
        i => this.runData.runners[i] || { ...this.runnerInitialState }
      );
    },
    decrementParticipant() {
      if (this.runData.quantity > 1) {
        this.runData.quantity--;
        this.onChangeMembers();
      }
    },
    incrementParticipant() {
      console.log("test");
      this.runData.quantity++;
      this.onChangeMembers();
    }
  }
});
</script>
