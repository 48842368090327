<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <span class="font-bold">{{ appState }}</span> {{ appTitle }}
      <ChevronRightIcon class="breadcrumb__icon" />
      <span class="breadcrumb--active">{{ pageTitle }}</span>
    </div>
    <!-- END: Breadcrumb -->
    <!-- BEGIN: View Team Button -->
    <div class="intro-x relative mr-3 sm:mr-6">
      <div v-if="user.team && teamSlug" class="search hidden sm:block">
        <a
          :href="publicTeamUrl"
          target="_blank"
          class="btn btn-outline-primary w-40 inline-block mr-1"
        >
          <ExternalLinkIcon class="w-4 h-4 mr-2" /> View My Team
        </a>
      </div>
    </div>
    <!-- END: View Team Button -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x w-8 h-8 relative">
      <div
        class="w-8 h-8 rounded-full text-center overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
        :class="{ 'bg-theme-26 text-white': showMenu }"
        @click="toggleMenu()"
      >
        <UserIcon v-show="!showMenu" class="w-5 h-5 mt-1 text-theme-1" />
        <XIcon v-show="showMenu" class="w-5 h-5 mt-1 text-white" />
      </div>
      <div v-show="showMenu" class="w-56 absolute left md:right-0 top-12">
        <div class="box bg-theme-26 dark:bg-dark-6 text-white">
          <div class="p-4 border-b border-theme-27 dark:border-dark-3">
            <div class="font-medium">
              {{ user.person.firstName }} {{ user.person.lastName }}
            </div>
            <div
              class="text-xs text-theme-28 mt-0.5 dark:text-gray-600 capitalize"
            >
              {{ user.role }}
            </div>
          </div>
          <div class="p-2">
            <router-link
              to="/my-profile"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="toggleMenu()"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Profile
            </router-link>
            <router-link
              to="/my-profile/change-password"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="toggleMenu()"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Change Password
            </router-link>
            <a
              class="flex cursor-pointer items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="runWalkthrough(), toggleMenu()"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" /> Show Walkthrough
            </a>
          </div>
          <div class="p-2 border-t border-theme-27 dark:border-dark-3">
            <button
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
              @click="logout(), toggleMenu()"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { defineComponent, computed, ref } from "vue";
import { useStore } from "@/store";
import { authLogout } from "@/api/auth.js";

export default defineComponent({
  props: {
    user: {
      type: Object,
      default: function() {
        return {};
      },
      required: true
    },
    teamSlug: {
      type: String,
      default: function() {
        return null;
      }
    },
    pageTitle: {
      type: String,
      default: function() {
        return "Dashboard";
      }
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const appTitle = computed(() => store.getters["main/appTitle"]);
    const appState = computed(() => store.getters["main/appState"]);
    const showMenu = ref(false);

    // const team = computed(() => store.getters["backEndTeam/currentTeam"]);

    const publicTeamUrl = "https://piecewalk.org/team/" + props.teamSlug;
    const logout = async () => {
      const refreshToken = store.state.auth.refreshToken;
      await authLogout(refreshToken);
      await store.dispatch("auth/logout");
      router.push({ path: "/login" });
    };

    const runWalkthrough = () => {
      window.localStorage.removeItem("pw_has_viewed_intro");
      console.log(route.name);
      if (route.name === "my-team") {
        router.go();
      } else {
        router.push({ path: "/" });
      }
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    return {
      appTitle,
      appState,
      toggleMenu,
      showMenu,
      logout,
      publicTeamUrl,
      runWalkthrough
    };
  }
});
</script>
