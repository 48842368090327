<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create New Password -->
    <form class="validate-form" @submit.prevent="setPassword">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Enter your new password below.
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Your participation can be applied to a specific team or the
                  general Walk event.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div>
                <div class="grid grid-cols-12 gap-4 gap-y-5">
                  <div class="intro-y col-span-12 sm:col-span-6">
                    <PasswordField v-model="password.password" />
                  </div>
                  <div class="intro-y col-span-12 sm:col-span-6">
                    <ConfirmPasswordField
                      v-model="password.confirm"
                      :password="password.password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center mt-6"
          >
            <button
              type="submit"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
            >
              Set Password
            </button>
          </div>
          <div
            v-if="error.status"
            class="error mt-6 font-bold text-red text-base pl-4 border-l-solid border-l-2 border-red"
          >
            <p>{{ error.message }}</p>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { defineComponent, onMounted, computed, reactive, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import CancelButton from "@/components/front-end/CancelButton";
import PasswordField from "@/components/form-inputs/Password";
import ConfirmPasswordField from "@/components/form-inputs/PasswordConfirm";
import { authResetPassword } from "@/api/auth.js";

export default defineComponent({
  components: {
    FrontEndHeader,
    CancelButton,
    PasswordField,
    ConfirmPasswordField
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const page = {
      title: "Reset Password"
    };

    const error = reactive({
      message: "",
      status: false
    });

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const rules = computed(() => {
      return {};
    });

    let password = reactive({
      password: ""
    });
    const validate = useVuelidate(rules, toRefs(password));

    const setPassword = async () => {
      validate.value.$touch();
      if (validate.value.$invalid) {
        console.log("invalid");
      } else {
        if (route.query.token) {
          let token = route.query.token;
          const resetPasswordResponse = await authResetPassword(
            token,
            password.password
          );
          if (resetPasswordResponse.message) {
            error.message = resetPasswordResponse.message;
            error.status = true;
          } else {
            router.push({ path: "/login" });
          }
        } else {
          console.log("invalid token");
          router.push({ path: "/login" });
        }
      }
    };

    return {
      page,
      validate,
      password,
      setPassword,
      error
    };
  }
});
</script>
