<template>
  <div>
    <FrontEndHeader fill="#be2032" />
    <!-- BEGIN: Create DonateLayout -->
    <form class="validate-form" @submit.prevent="save">
      <div class="intro-y box mt-5 pb-10 max-w-3xl mx-auto">
        <div
          class="font-bold text-3xl px-5 sm:px-20 py-10 pb-5 sm:py-10 rounded-t"
        >
          {{ page.title }}
        </div>
        <div class="px-5 sm:px-20">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-3">
              <div class="px-0 sm:pr-8">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  How would you like to apply your funds and participation?
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Your participation can be applied to a specific team or the
                  general Walk event.
                </p>
              </div>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-3">
              <div>
                <div class="grid grid-cols-12 gap-4 gap-y-5">
                  <Supporting
                    v-model:supportType="supporting.type"
                    v-model:eventCity="supporting.eventDetails"
                    v-model:teamSelected="supporting.teamDetails"
                    :support-type="supporting.type"
                    :event-city-value="supporting.eventDetails"
                    :team-selected="supporting.teamDetails"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center mt-6"
          >
            <button
              v-show="
                (supporting.teamDetails.name && supporting.type === 'Team') ||
                  (supporting.eventDetails.city && supporting.type === 'Event')
              "
              type="button"
              class="btn block w-full intro-y py-3 sm:inline-block sm:w-auto btn-red border-reddark sm:ml-2 sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0"
              @click="setSupporting()"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
    <CancelButton />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { defineComponent, onMounted, ref, computed, toRefs } from "vue";
import { useStore } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import Supporting from "@/components/form-inputs/Supporting";
import FrontEndHeader from "@/components/front-end/FrontEndHeader";
import CancelButton from "@/components/front-end/CancelButton";

export default defineComponent({
  components: {
    Supporting,
    FrontEndHeader,
    CancelButton
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let validateFields = ref(false);
    let resetFields = ref(false);
    const store = useStore();
    const supporting = store.state.support.supporting;

    const setSupporting = () => {
      store.dispatch("support/setSupporting", supporting);
      if (route.query.donate) {
        router.push({ path: "/frontend/donate" });
      } else {
        router.push({ path: "/frontend/contact", query: { skip: true } });
      }
    };

    const page = {
      title: "Support"
    };

    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("frontend create-team");
    });

    const rules = computed(() => {
      return {};
    });

    const validate = useVuelidate(rules, toRefs(supporting));

    return {
      page,
      supporting,
      validate,
      validateFields,
      resetFields,
      setSupporting
    };
  }
});
</script>
