<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pageTitle }}</h2>
    </div>
    <!-- BEGIN: Email My Team -->
    <div v-show="!showEmailForm" class="intro-y box px-5 pt-9 mt-5 text-center">
      <h4 class="text-lg font-bold">How would you like to send your email?</h4>
      <p>
        We would like to give you an option for how you reach out to your team.
      </p>
      <div class="grid grid-cols-12 gap-6 mb-6 mt-6">
        <div
          class="col-span-12 lg:col-span-6 border border-solid border-gray-200 p-6"
        >
          <h3 class="text-base text-red mb-4">Using your email client</h3>
          <p class="mb-4">
            If you would like to send your email through Apple Mail, Outlook, or
            your native email platform. Click the button below to launch an
            email to your team.
          </p>
          <a
            :href="emailTeam"
            class="btn w-full mb-2 btn-red flex-grow md:ml-6 md:w-40"
            @click="returnToMyTeam()"
          >
            Use My Email Client
          </a>
          <p class="italic font-sm mt-12">
            Clicking the button above should launch your native email client
            (Apple Mail, Outlook, etc.)
          </p>
        </div>
        <div
          class="col-span-12 lg:col-span-6 border-l border border-gray-200 p-6"
        >
          <h3 class="text-base text-red mb-4">Use our email form</h3>
          <p class="mb-4">
            If you do not have an email client installed on your computer and
            would like us to send an email on your behalf click below. Your team
            will still be able to respond to you from the email.
          </p>
          <button
            class="btn w-full mb-2 btn-red flex-grow md:ml-6 md:w-40"
            @click="toggleShowEmailForm"
          >
            Use Email Form
          </button>
          <p class="italic font-sm mt-12">
            Clicking the button will allow you to send an email through our
            built-in form.
          </p>
        </div>
      </div>
    </div>
    <div v-show="showEmailForm" class="intro-y box px-5 pt-9 mt-5 text-center">
      <h4 class="text-lg font-bold">Send Your Team An Email</h4>
      <form class="validate-form" @submit.prevent="sendEmail">
        <div
          class="flex flex-wrap flex-col justify-center items-center mb-6 mt-6"
        >
          <div class="w-full lg:w-2/3">
            <EmailForm
              v-model:emailSubject="newEmail.subject"
              v-model:emailMessage="newEmail.message"
              :email-subject="newEmail.subject"
              :email-message="newEmail.message"
            />
          </div>
          <div class="col-span-12 mt-6">
            <button
              type="submit"
              class="btn block mt-4 w-full intro-y py-3 sm:mt-0 sm:inline-block sm:w-auto btn-red border-reddark sm:px-10 btn-lg"
              :disabled="validate.$errors.length > 0 || processing"
            >
              Send Message
            </button>
          </div>
          <div class="col-span-12 mt-12">
            <button
              type="button"
              class="btn w-full mb-2 btn-outline flex-grow md:ml-6 md:w-40 mb-12"
              @click="toggleShowEmailForm"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  computed,
  toRefs
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { useVuelidate } from "@vuelidate/core";
import { getTeam } from "@/api/teams.js";
import { emailSend } from "@/api/email";
import EmailForm from "@/components/form-inputs/EmailForm";

export default defineComponent({
  components: {
    EmailForm
  },
  setup() {
    const team = reactive({});
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let pageTitle = ref("Email My Team");
    let showEmailForm = ref(false);
    let processing = ref(false);

    let baseEmail = {
      replyTo: "",
      to: [],
      subject: "",
      message: ""
    };

    let newEmail = reactive(baseEmail);

    const setTeam = async () => {
      let theTeamID = "";
      if (route.params.teamId) {
        theTeamID = route.params.teamId;
      }
      const getMyTeam = await getTeam(theTeamID);
      Object.assign(team, getMyTeam);
    };

    const emailTeam = computed(() => {
      let emailList = [];
      let subject = "";

      if (team.members && team.name) {
        subject = `AutismOklahoma Walk - ${team.name}!`;
        team.members.map(member => {
          let email = member.email;
          emailList.push(email);
        });
      }

      let emailListForSend = emailList.join(",");
      return `mailto:${emailListForSend}?subject=${subject}`;
    });

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .removeClass("frontend")
        .addClass("team-config");
      await setTeam();
    });

    const returnToMyTeam = () => {
      router.push({
        name: "my-team"
      });
    };

    const toggleShowEmailForm = () => {
      showEmailForm.value = !showEmailForm.value;
    };

    const rules = computed(() => {
      return {};
    });

    const validate = useVuelidate(rules, toRefs(newEmail));

    const sendNotification = async response => {
      let note = {
        show: true,
        title: "Success",
        message: "Your email has been sent."
      };
      if (response.code) {
        note = {
          show: true,
          error: true,
          title: "Error",
          message: "There was a problem sending your email."
        };
      }
      await store.dispatch("notifications/displayNotification", note);
    };

    const emailTeamArray = computed(() => {
      let emailList = [];
      if (team.members && team.name) {
        team.members.map(member => {
          let email = member.email;
          emailList.push(email);
        });
      }
      return emailList;
    });

    const sendEmail = async () => {
      validate.value.$touch();
      newEmail.replyTo = team.captainPerson.email;
      newEmail.to = emailTeamArray.value;
      console.log(newEmail);
      if (validate.value.$invalid) {
        console.log("Not Filled Out.");
        await sendNotification({ code: 400 });
      } else {
        processing.value = true;
        const sendEmailResult = await emailSend(newEmail);
        // const sendEmailResult = {};
        await sendNotification(sendEmailResult);
        returnToMyTeam();
      }
    };

    return {
      pageTitle,
      team,
      emailTeam,
      returnToMyTeam,
      toggleShowEmailForm,
      showEmailForm,
      newEmail,
      validate,
      sendEmail,
      processing
    };
  }
});
</script>
